import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { getCountry, getLang, getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { getImpressum } from 'i18n/preferences';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { getPortalSocial } from 'util/getPortalConfig';
import { IS_SSR } from 'ots-constants';
import { Routes } from 'routes';

import { UnitToggle } from 'components/blocks/Footer/UnitToggle';
import { ReactComponent as SvgApple } from 'public/assets/icons/apple.svg';
import { ReactComponent as SvgFacebook } from 'public/assets/icons/facebook.svg';
import { ReactComponent as SvgGoogle } from 'public/assets/icons/google-play.svg';
import { ReactComponent as SvgInstagram } from 'public/assets/icons/instagram.svg';
import { ReactComponent as SvgTwitter } from 'public/assets/icons/twitter.svg';
import { ReactComponent as SvgYoutube } from 'public/assets/icons/youtube.svg';
import { ReactComponent as SvgMntBig } from 'public/assets/resort/big-mountain.svg';
import { ReactComponent as SvgMntGray } from 'public/assets/resort/small-mountain-gray.svg';
import { ReactComponent as SvgMntSmall } from 'public/assets/resort/small-mountain.svg';
import { ReactComponent as SvgLogo } from 'public/assets/white-logo.svg';
import { ReactComponent as SvgLogoSki } from 'public/assets/white-logo_ski.svg';
import { useNoHeader } from 'util/useNoHeader';
import { isMainLocale } from 'util/i18nFlags';

import { I18nKey } from 'i18n/types';
import { ONE_TRUST_CONFIG } from 'onetrust/constants';
import { useSettingsPrefetch } from 'settings/SettingsContext';
import { SimpleLangSelect } from 'components/resort/FooterLangSelect/FooterLangSelect';
import { useShouldAdjustForSummer } from 'util/useShouldAdjustForSummer';
import styles from './styles.module.scss';

const getAppleStoreUrl = () => {
  const locale = getLocale() as ELocale;
  const addLangParam = ['pl-PL', 'cs-CZ', 'sk-SK', 'nl-NL', 'es-ES', 'sv-SE', 'da-DK'].includes(locale);
  const region = locale !== ELocale.SV_SE ? getCountry().toLowerCase() : getLang().toLowerCase();
  if (addLangParam) {
    return `https://apps.apple.com/${region}/app/ski-snow-report/id${process.env.NEXT_PUBLIC_APPSTORE_ID}?l=${getLang().toLowerCase()}`;
  }
  return `https://apps.apple.com/${region}/app/ski-snow-report/id${process.env.NEXT_PUBLIC_APPSTORE_ID}`;
};

const googleStoreLocaleSuffixMap: Record<ELocale, string> = {
  [ELocale.FR_FR]: 'hl=fr',
  [ELocale.DE_DE]: 'hl=de',
  [ELocale.EN_GB]: 'hl=en_gb',
  [ELocale.IT_IT]: 'hl=it',
  [ELocale.PL_PL]: 'hl=pl',
  [ELocale.CS_CZ]: 'hl=cs',
  [ELocale.SK_SK]: 'hl=sk',
  [ELocale.EN_US]: 'feature=related_apps',
  [ELocale.NL_NL]: 'feature=related_apps',
  [ELocale.NN_NO]: 'feature=related_apps',
  [ELocale.ES_ES]: 'feature=related_apps',
  [ELocale.SV_SE]: 'feature=related_apps',
  [ELocale.DA_DK]: 'feature=related_apps',
};

const getGoogleStoreUrl = () => {
  const locale = getLocale() as ELocale;
  return `https://play.google.com/store/apps/details?id=${process.env.NEXT_PUBLIC_GOOGLEPLAY_ID}&${googleStoreLocaleSuffixMap[locale]}`;
};

export const socialLinks = {
  facebook: `https://www.facebook.com/${getPortalSocial().facebook}`,
  twitter: `https://www.twitter.com/${getPortalSocial().twitter}`,
  instagram: `https://www.instagram.com/${getPortalSocial().instagram}`,
  youtube: `https://www.youtube.com/user/${getPortalSocial().youtube}`,
  apple: getAppleStoreUrl(),
  google: getGoogleStoreUrl(),
};

type PigeonProps = {
  href: string,
  trKey: I18nKey['common'],
  fallback: string,
};

export const PigeonManageLink = () => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { t } = useOTSTranslation('common');

  const updatePigeonProps = useCallback(() => {
    const props: PigeonProps = (global as any).pigeonProps;
    if (!props || !ref.current) {
      return undefined;
    }
    ref.current.innerText = t(props.trKey, {
      defaultValue: props.fallback,
    });
    ref.current.href = props.href;
    return undefined;
  }, [ref]);
  (global as any).updatePigeonProps = updatePigeonProps;
  useEffect(() => {
    if (!IS_SSR) {
      updatePigeonProps();
    }
  }, [updatePigeonProps]);

  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  return <a ref={ref} id="pigeon-btn" className="pigeon-settings">{/* Pigeon will insert label here */}</a>;
};

export const OneTrustManageLink = () => {
  const [isDomLoaded, setIsDomLoaded] = useState(false);
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (!IS_SSR) {
      const t = setInterval(() => {
        if (ref.current && ref.current.innerText.trim()) {
          // OneTrust did own job
          clearInterval(t);
        } else if (typeof OneTrust !== 'undefined') {
          if (ref.current) {
            clearInterval(t);
            ref.current.innerHTML = OneTrust.GetDomainData().CookieSettingButtonText;
            ref.current.addEventListener('click', () => OneTrust.ToggleInfoDisplay());
          }
        }
      }, 500);

      return () => clearInterval(t);
    }
    return () => undefined;
  }, []);

  useEffect(() => {
    setIsDomLoaded(true);
  }, []);

  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
  return <>{isDomLoaded && <a ref={ref} id="ot-sdk-btn" className="ot-sdk-show-settings">{/* OneTrust will insert label here */}</a>}</>;
};

export const SmartManagementLink = () => {
  const locale = getLocale() as ELocale;
  const config = ONE_TRUST_CONFIG[locale];
  const hasPigeon = !!config.pigeon;

  if (hasPigeon) {
    return <PigeonManageLink />;
  }
  return <OneTrustManageLink />;
};

export const Footer = () => {
  const prefetch = useSettingsPrefetch();
  const { t } = useOTSTranslation('common');
  const impressum = getImpressum();
  const showUSLogo = [ELocale.EN_US, ELocale.EN_GB, ELocale.CS_CZ, ELocale.SK_SK].includes(getLocale() as ELocale);
  const shouldAdjustForSummer = useShouldAdjustForSummer();

  const { noHeader } = useNoHeader();
  if (noHeader) {
    return null;
  }

  return (
    <div className={styles.box}>
      <div className={styles.top}>
        <div className={styles.mountains}>
          <SvgMntGray className={styles.m2} />
          <SvgMntBig className={styles.m1} />
          <SvgMntSmall className={styles.m3} />
        </div>
        <div className={styles.content}>
          <div className="container-xl">
            <div className={styles.left}>
              <nav className={styles.bold}>
                <Link
                  scroll={false}
                  href={Routes.root}
                  prefetch={prefetch}
                  aria-label="Landing"
                  legacyBehavior
                >

                  {showUSLogo ? <SvgLogo className={styles.logo} /> : <SvgLogoSki className={styles.logo} />}
                </Link>
                {shouldAdjustForSummer && (
                <div className={styles.langPicker}>
                  <SimpleLangSelect />
                </div>
                )}
                <div className={styles.navLinksContainer}>
                  <div className={classNames(styles.block, styles.main)}>
                    <a href={Routes.contactOTS}>
                      {t('nav.footer.contactOts')}
                    </a>
                    <span>/</span>
                    <Link scroll={false} href={Routes.advertise} prefetch={prefetch} legacyBehavior>

                      {t('nav.footer.advertise')}

                    </Link>
                  </div>
                  <div className={classNames(styles.block, styles.support)}>
                    {isMainLocale() && (
                      <a href={Routes.newsletter(t('nav.global.newsletter'))}>
                        {t('nav.footer.newsletter')}
                      </a>
                    )}
                  </div>
                </div>
              </nav>
              <nav className={styles.small}>
                {impressum && (
                  <>
                    <a href={Routes.impressum}>
                      {t('nav.footer.legalNotice')}
                    </a>
                    <span>/</span>
                  </>
                )}
                <a href={Routes.privacyPolicy}>
                  {t('nav.footer.privacyPolicy')}
                </a>
                <span>/</span>
                <a href={Routes.tos}>
                  {t('nav.footer.TermsConditions')}
                </a>
                <span>/</span>
                <a href={Routes.sitemap}>
                  {t('common.sitemapHeader')}
                </a>
                <span>/</span>
                <UnitToggle />
                <SmartManagementLink />
              </nav>
            </div>
            <div className={styles.right}>
              <nav className={styles.social}>
                <Link
                  href={socialLinks.youtube}
                  aria-label="Youtube"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgYoutube />
                </Link>
                <Link
                  href={socialLinks.twitter}
                  aria-label="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgTwitter />
                </Link>
                <Link
                  href={socialLinks.instagram}
                  aria-label="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgInstagram />
                </Link>
                <Link
                  href={socialLinks.facebook}
                  aria-label="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgFacebook />
                </Link>
              </nav>
              <nav className={styles.apps}>
                <Link
                  href={socialLinks.google}
                  aria-label="Google Play"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgGoogle />
                </Link>
                <Link
                  href={socialLinks.apple}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Apple Store"
                >
                  <SvgApple />
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        {t('nav.footer.copyrightMncAllRightsReserved', { value: new Date().getFullYear() })}
      </div>
    </div>
  );
};

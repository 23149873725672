import classNames from 'classnames';
import Link from 'next/link';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Routes } from 'routes';
import { isMainLocale } from 'util/i18nFlags';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { useSettingsPrefetch } from 'settings/SettingsContext';
import { ReactComponent as SvgLogoWhiteSummer } from 'public/assets/icons/logos/white-summer-mobile.svg';
import { ReactComponent as SvgSearch } from 'public/assets/icons/search.svg';
import { ReactComponent as SvgSunWhite } from 'public/assets/icons/sun-white.svg';
import { useAnalytics } from 'util/analytics';
import { setFocusToSearchInput } from 'util/setFocusToInputByQuery';
import { SearchPopup } from 'components/home/SearchPopup';
import { locale } from 'i18n/constants';
import styles from './MobileSummerHeader.module.scss';
import { SUMMER_NAV_BAR_URLS } from './summerURLs';

type SummerNavLocales = 'en-US' | 'en-GB' | 'fr-FR' | 'de-DE' | 'it-IT' | 'pl-PL';

const summerNavLocales: SummerNavLocales[] = ['en-US', 'en-GB', 'fr-FR', 'de-DE', 'it-IT', 'pl-PL'];

const isSummerLocale = summerNavLocales.includes(locale as SummerNavLocales);

const summerURLs = isSummerLocale && SUMMER_NAV_BAR_URLS[locale as SummerNavLocales];

export enum HeaderNavCurLink {
  SNOW_REPORTS = 'snow_reports',
  TRIP_PLANNING = 'trip_planning',
  MAGAZINE = 'magazine',
  MAIN = 'index',
}

interface Props {
  current: HeaderNavCurLink
}

export const MobileSummerHeader = ({ current }: Props) => {
  const { t } = useOTSTranslation('common');
  const prefetch = useSettingsPrefetch();

  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const a = useAnalytics();

  const toggleSearch = useCallback(() => {
    new Promise<void>((resolve) => {
      setSearchOpen(!isSearchOpen);
      if (isSearchOpen) {
        a.searchBox();
      }
      resolve();
    }).then(() => {
      setFocusToSearchInput();
    });
  }, [isSearchOpen]);

  useEffect(() => () => document.body.classList.remove('search-overlay'), []);

  return (
    <div className={styles.container}>
      {
        isSearchOpen && <SearchPopup closePopup={toggleSearch} />
      }
      <div className={styles.row}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <Link
              scroll={false}
              href={Routes.root}
              prefetch={prefetch}
              tabIndex={-1}
            >

              <SvgLogoWhiteSummer />

            </Link>
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <Link
              scroll={false}
              href={Routes.snowReports()}
              prefetch={prefetch}
              className={classNames({
                [styles.current]: current === HeaderNavCurLink.SNOW_REPORTS,
              })}
              legacyBehavior
            >

              {t('nav.global.snowWeather')}

            </Link>
          </div>
          {isMainLocale() && (
            (
              <div>
                <Link
                  scroll={false}
                  href={Routes.tripPlanning()}
                  prefetch={prefetch}
                  className={classNames({
                    [styles.current]: current === HeaderNavCurLink.TRIP_PLANNING,
                  })}
                  legacyBehavior
                >

                  {t('nav.global.travel')}

                </Link>
              </div>
            )
          )}
          <div className={styles.active}>
            <Link
              href={Routes.magazine}
              className={classNames({
                [styles.current]: current === HeaderNavCurLink.MAGAZINE,
              }, styles.dropdownActivator)}
            >
              {t('nav.global.summer.stories')}
            </Link>
          </div>
        </div>
        <div className={styles.row}>
          <div tabIndex={-1} role="button" className={styles.searchToggle} onClick={toggleSearch}>
            <SvgSearch />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        {summerURLs && summerURLs.slice(0, 2).map((item, index) => (
          <div>
            <Link
              href={item.url}
            >
              {t(item.key as I18nKeyGenerated['common'])}
            </Link>
            {index === 1 && <SvgSunWhite />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileSummerHeader;

export const SUMMER_NAV_BAR_URLS = {
  'en-US': [
    { key: 'nav.global.winterSwitch', url: 'https://www.onthesnow.com/news/' },
    { key: 'nav.global.summer.summer', url: 'https://www.mountaingetaway.co/' },
    { key: 'newsroom.article.weather', url: 'https://www.onthesnow.com/news/weather/' },
    { key: 'newsroom.article.catLiftTicketDeals', url: 'https://www.onthesnow.com/news/lift-passes/' },
    { key: 'newsroom.article.catGearGuide', url: 'https://www.onthesnow.com/news/gear/' },
    { key: 'newsroom.article.catTravelFature', url: 'https://www.onthesnow.com/news/travel/' },
    { key: 'newsroom.article.catHowto', url: 'https://www.onthesnow.com/news/how-to/' },
    { key: 'newsroom.article.catBestOfTopics', url: 'https://www.onthesnow.com/news/travel/best-of-topics/' },
  ],
  'en-GB': [
    { key: 'nav.global.winterSwitch', url: 'https://www.onthesnow.co.uk/news/' },
    { key: 'nav.global.summer.summer', url: 'https://www.mountainnews.co.uk/' },
    { key: 'newsroom.article.catFamilySki', url: 'https://www.onthesnow.co.uk/news/family-skiing/' },
    { key: 'newsroom.article.catTravelFature', url: 'https://www.onthesnow.co.uk/news/ski-destinations/' },
    { key: 'newsroom.article.catBestOfTopics', url: 'https://www.onthesnow.co.uk/news/best-of-topics/' },
    { key: 'newsroom.article.catGearGuide', url: 'https://www.onthesnow.co.uk/news/gear/' },
    { key: 'newsroom.article.catPopular', url: 'https://www.onthesnow.co.uk/news/popular/' },
    { key: 'newsroom.article.catYearRoundSkiing', url: 'https://www.onthesnow.co.uk/news/year-round-skiing/' },
  ],
  'fr-FR': [
    { key: 'nav.global.winterSwitch', url: 'https://www.skiinfo.fr/news/' },
    { key: 'nav.global.summer.summer', url: 'https://www.mountainnews.fr/' },
    { key: 'newsroom.article.weather', url: 'https://www.skiinfo.fr/news/meteo/' },
    { key: 'newsroom.article.catTravelFature', url: 'https://www.onthesnow.co.uk/news/ski-destinations/' },
    { key: 'newsroom.article.catLiftTicketDeals', url: 'https://www.skiinfo.fr/news/tarifs-et-bons-plans-forfait-de-ski/' },
    { key: 'newsroom.article.catGearGuide', url: 'https://www.skiinfo.fr/news/materiel/' },
    { key: 'newsroom.article.catLodging', url: 'https://www.skiinfo.fr/news/hebergement/' },
    { key: 'newsroom.article.catActivities', url: 'https://www.skiinfo.fr/news/activites/' },
  ],
  'it-IT': [
    { key: 'nav.global.winterSwitch', url: 'https://www.skiinfo.it/news/' },
    { key: 'nav.global.summer.summer', url: 'https://www.mountainnews.it/' },
    { key: 'newsroom.article.weather', url: 'https://www.skiinfo.it/news/meteo/' },
    { key: 'newsroom.article.catResortFeature', url: 'https://www.skiinfo.it/news/news-dalle-stazioni-sciistiche/' },
    { key: 'newsroom.article.catGearGuide', url: 'https://www.skiinfo.it/news/attrezzatura/' },
    { key: 'newsroom.article.catTravelFature', url: 'https://www.skiinfo.it/news/viaggi/' },
    { key: 'newsroom.article.catHowto', url: 'https://www.skiinfo.it/news/consigli-e-suggerimenti/' },
    { key: 'newsroom.article.catPopular', url: 'https://www.skiinfo.it/news/popular/' },
  ],
  'de-DE': [
    { key: 'nav.global.winterSwitch', url: 'https://www.skiinfo.de/news/' },
    { key: 'nav.global.summer.summer', url: 'https://www.mountainnews.de/' },
    { key: 'newsroom.article.catResortFeature', url: 'https://www.skiinfo.de/news/reisen/skigebiets-news/' },
    { key: 'newsroom.article.catGearGuide', url: 'https://www.skiinfo.de/news/ausruestung/' },
    { key: 'newsroom.article.catFitness', url: 'https://www.skiinfo.de/news/wissenswertes/gesundheit-fitness/' },
    { key: 'newsroom.article.catTravelFature', url: 'https://www.skiinfo.de/news/reisen/reiseziele/' },
    { key: 'newsroom.article.catHowto', url: 'https://www.skiinfo.de/news/ausruestung/ratgeber/' },
    { key: 'newsroom.article.catBestOfTopics', url: 'https://www.skiinfo.de/news/reisen/beste-themen/' },
  ],
  'pl-PL': [
    { key: 'nav.global.winterSwitch', url: 'https://www.skiinfo.pl/news/' },
    { key: 'nav.global.summer.summer', url: 'https://www.mountainnews.pl/' },
    { key: 'newsroom.article.catBestOfTopics', url: 'https://www.skiinfo.pl/news/narciarski-top/' },
    { key: 'newsroom.article.catTravelFature', url: 'https://www.skiinfo.pl/news/podroze/narciarskie-podroze/' },
    { key: 'newsroom.article.weather', url: 'https://www.skiinfo.pl/news/pogoda/' },
    { key: 'newsroom.article.catFamilySki', url: 'https://www.skiinfo.pl/news/podroze/narty-z-rodzina/' },
    { key: 'newsroom.article.catGearGuide', url: 'https://www.skiinfo.pl/news/sprzet-i-wyposazenie/' },
    { key: 'newsroom.article.catHowto', url: 'https://www.skiinfo.pl/news/poradnik/' },
  ],
};

import classNames from 'classnames';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { localeConfig } from 'localeConfig';
import { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { options } from '../HeaderLangSelect/HeaderLangSelect';

export const SimpleLangSelect = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const links = options.map((o) => (
    <a className="lang-select" key={o.flag} href={localeConfig[o.value as ELocale].websiteDomain}>
      <div>
        {visible && (
        <img
          src={o.flag}
          alt={o.label}
          width={18}
          height={18}
          title={o.title}
        />
        )}
        <span>
          {o.title}
        </span>
      </div>
    </a>
  ));

  const value = options.find((o) => o.value === getLocale())!;

  const hideLangSelect = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const showLangSelect = useCallback(() => {
    setVisible(true);
  }, [setVisible, hideLangSelect]);

  useEffect(() => {
    const handleOutsideClick = (event:MouseEvent) => {
      const target = event.target as HTMLElement;

      // If clicked outside of the dropdown
      if (!target.closest('.lang-select')) {
        hideLangSelect();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [visible, hideLangSelect]);

  return (
    <div className={styles.langWrapper}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
      <a
        onClick={showLangSelect}
        className={styles.langButton}
      >
        <div>
          <img
            src={value.flag}
            alt={value.label}
            width={18}
            height={18}
            title={value.title}
          />
          <span>
            {value.title}
          </span>
        </div>
      </a>
      <div className={classNames(styles.langDropdown, visible && styles.visible)}>
        {links}
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Check if the window object is available (only on the client-side)
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      // Set initial window dimensions after component mounts
      handleResize();

      // Add resize event listener
      window.addEventListener('resize', handleResize);

      // Return cleanup function to remove the event listener
      return () => window.removeEventListener('resize', handleResize);
    }

    // Explicitly return undefined if window is not available
    return undefined;
  }, []); // Empty dependency array to run the effect only once

  return windowDimensions;
};

export default useWindowDimensions;
